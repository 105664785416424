import React, { Component } from 'react'

import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Favicon from 'react-favicon';
import {Link} from 'react-router-dom'
import psl from 'psl';

import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import Navbar from '../../Layout/Navbar/Navbar'
import StatusIndicator from '../../Layout/StatusIndicator/StatusIndicator';
import Footer from '../../Layout/Footer/Footer'
import {getIncidentEvents , diffDates , durationToString, getMaintenanceEvents} from './../../../utils/helper'
// Invoke the global state
import AppContext from './../../../context/app-context'
// import css design 
import './incidents.css'
// import icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'
import { ReactComponent as BackIcon } from './../../../assets/icons/previous.svg'

export default class IncidentDetails extends Component {

	static contextType = AppContext
    signal = axios.CancelToken.source();

    constructor(props){
        super(props)
        this.state = {
			incidentId : props.match.params.incidentId,
            statusPageType: 'hosted-subdomain',//hosted-subdomain or custom-domain
			statusPageDomain: '',//'gdfgdfgdf',
			statusPageNativeDomain: '',
			statusPageHomePageLink: null,
			statusPageLogoUrl: null,
			statusPageName: null,
			statusPageChartColor: null,
			statusPageBackgroundColor: null,
            globalStatus: 'up',
			averagePingsDataPerMonitor: {},
			regex_hostedDomain: "^([A-Za-z0-9\-]+)\.(odown.io)",
            isLoading : false ,
            incidentList : [],
            isLoading_statusPageInfo : false ,
            notFound: false,
			incidentData : null,
			pings_1d : [],
			affectedMonitors : [],
			allowSubscriptions : false ,
			allowedChannelsSubs : [] , 
			availableDateRanges: [
				{
					name: "12 months",
					handle : '12months' ,
					nb_days: 365,
					selected: false
				},
				{
					name: "3 months",
					handle : '3months' ,
					nb_days: 91,
					selected: false
				},
				{
					name: "1 month",
					handle : '1month' ,
					nb_days: 30,
					selected: false
				},
				{
					name: "1 week",
					handle : '1week' ,
					nb_days: 7,
					selected: false
				},
				{
					name: "24 hours",
					handle : '24hours' ,
					nb_days: 1,
					selected: true
				}
			],
        }   

        this.getStatusPageInfo = this.getStatusPageInfo.bind(this);
        this.getIncidentInfo = this.getIncidentInfo.bind(this);
        this.collectAllAffectedMonitors = this.collectAllAffectedMonitors.bind(this);
    }

    getStatusPageInfo() {


		this.setState({
			isLoading_statusPageInfo: true
		});

		
		const globalStatusPageData = this.context.getStatusPageData();

		if(typeof globalStatusPageData!=='undefined' && globalStatusPageData!==null){
			this.setState({

				monitorItems : globalStatusPageData.monitorItems,
				statusPageHomePageLink : globalStatusPageData.statusPageHomePageLink,
				statusPageLogoUrl : globalStatusPageData.statusPageLogoUrl,
				statusPageName : globalStatusPageData.statusPageName,
				statusPageChartColor : globalStatusPageData.statusPageChartColor,
				statusPageBackgroundColor : globalStatusPageData.statusPageBackgroundColor,
				globalStatus : globalStatusPageData.globalStatus,
				pings_1d : globalStatusPageData.pings_1d,
				averagePingsDataPerMonitor : globalStatusPageData.averagePingsDataPerMonitor,
				monitorInfo : globalStatusPageData.monitorInfo,
				isLoading_statusPageInfo : false ,
				allowSubscriptions : globalStatusPageData.allowSubscriptions,
				allowedChannelsSubs : globalStatusPageData.allowedChannelsSubs,
				activeRanges : globalStatusPageData.activeRanges,
				chart_parameters : globalStatusPageData.chart_parameters,
				showOpenIncidents : globalStatusPageData.showOpenIncidents,
				availableDateRanges : globalStatusPageData.availableDateRanges,
				outageRanges : globalStatusPageData.outageRanges,
				statusPageNativeDomain : globalStatusPageData.statusPageNativeDomain,
			})

			return 
		}

		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

				if (typeof data.monitors !== 'undefined' && data.monitors !== null && data.monitors.length > 0) {
					stateItems.monitorItems = data.monitors;
					stateItems.statusPageHomePageLink = data.homepage_link;
					stateItems.statusPageLogoUrl = data.logo;
					stateItems.statusPageName = data.name;
					stateItems.statusPageChartColor = data.color;
					stateItems.statusPageBackgroundColor = data.background_color;
					stateItems.allowSubscriptions = data.allow_subscriptions;
					stateItems.activeRanges = Object.keys(data.chart_options).filter(x => data.chart_options[x].is_active === true);
					stateItems.chart_parameters = data.chart_options;
					stateItems.showOpenIncidents = data.display_open_incidents;
					stateItems.allowedChannelsSubs = data.allowed_channel_subs;
					stateItems.statusPageAccessInfo = {
						type : this.state.statusPageType,
						domain : this.state.statusPageDomain,
					};
					stateItems.outageRanges = data.incidents;

					
					// fetch all monitors (one by one )
					let downMonitorCount = 0;
					Promise.all(data.monitors.map(item => {

						if (item.is_down === true) downMonitorCount++;

						// update 24 hours pings ( pings_1d)
						let pings_1d = this.state.pings_1d
						pings_1d.push({
							monitorId: item.id,
							data: item.pings
						});
						stateItems.pings_1d = pings_1d;
						 
						// calculate the uptime and responseTime average of 24 ping Data 
						stateItems.averagePingsDataPerMonitor = this.calculateAveragePingsData(this.state.averagePingsDataPerMonitor, item.id, item.pings, '1d');

						// this.getMonitorResults(item.id, 365);
						 
					}));


					// validate the global status of all monitors in the current statuspage
					if (downMonitorCount === 0) stateItems.globalStatus = 'up';
					else if (downMonitorCount === data.monitors.length) stateItems.globalStatus = 'down';
					else if (downMonitorCount < data.monitors.length && downMonitorCount > 0) stateItems.globalStatus = 'partial';

					stateItems.monitorInfo = data;


					// ? Change the default selected range
					let tryCount = 0 ; 
					// console.log('Before stateItems.availableDateRanges : ' , this.state.availableDateRanges);
					// console.log('Before activeRanges : ' , stateItems.activeRanges);
					stateItems.availableDateRanges = this.state.availableDateRanges
						.sort((a,b) => (a.nb_days > b.nb_days) ? 1 : ((b.nb_days > a.nb_days) ? -1 : 0))	
						.map( x=> {
						if(stateItems.activeRanges.includes(x.handle) && tryCount === 0) {
							x.selected = true ;
							tryCount++;
							return x;
						}else {
							x.selected = false 
							return x
						}
					})

					// ? if the status page settings has a homepage url then we extract the native domain 
					// ? name from it else we let the preview native domain name detecter  
					if(typeof data.homepage_link!='undefined' && data.homepage_link!==null){

						const url = data.homepage_link
						// console.log('url : ' , url )
						const hostname = new URL(url).hostname;
						// console.log('hostname : ' , hostname )
						
						// Get the native domain name from the url (Parse domain without subdomain) 
						var parsedURL = psl.parse(hostname);
						// console.log('parsedURL : ' , parsedURL )
						stateItems.statusPageNativeDomain = parsedURL.domain;
					}
				}

				stateItems.isLoading_statusPageInfo = false;
				this.setState(stateItems,  () => {
					// ! Save as a global state 
					this.context.setStatusPageData(stateItems) 
				});
			})
			.catch(error => {

				// console.log(error)

				let stateItems = {}
				stateItems.notFound = true;

				if (axios.isCancel(error)) {
				} else {
					stateItems.isLoading_statusPageInfo = false
				}


				this.setState(stateItems);
			})
	}

    getIncidentInfo(){
        this.setState({
			isLoading : true
		});

        axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}/incidents/${this.state.incidentId}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

			

				let stateItems = {}

				if(typeof data.events!=='undefined' && data.events!==null && data.events.length > 0) {
							
					data.events.sort((a,b) => moment(b.created_at).unix() - moment(a.created_at).unix())

				}

				stateItems.incidentData = data
				this.collectAllAffectedMonitors(data)

				stateItems.isLoading = false;
				this.setState(stateItems);
			})
			.catch(error => {

				let stateItems = {}

				if (axios.isCancel(error)) {
				} else {
					stateItems.isLoading = false
				}


				this.setState(stateItems);
			})

    }

	/* getMonitorResults(monitorId, limit = 365) {
		return new Promise(async (resolve, reject) => {

			this.start_monitorLoading(monitorId)

			axios
				.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}/monitors/${monitorId}/results`, {
					params: {
						limit: (limit > 365) ? 365 : limit
					},
					cancelToken: this.signal.token
				})
				.then(res => res.data)
				.then(data => {


					let pings_365d = this.state.pings_365d;
					let pings_7d = this.state.pings_7d;
					let pings_30d = this.state.pings_30d;
					let pings_91d = this.state.pings_91d;


					const found_365d = pings_365d.findIndex(x => x.monitorId === monitorId)
					const found_7d = pings_7d.findIndex(x => x.monitorId === monitorId)
					const found_30d = pings_30d.findIndex(x => x.monitorId === monitorId)
					const found_91d = pings_91d.findIndex(x => x.monitorId === monitorId)

					let averagePingsDataPerMonitor = this.state.averagePingsDataPerMonitor;


					if (typeof found_365d === 'undefined' || found_365d < 0) {

						pings_365d.push({
							monitorId: monitorId,
							data: data
						})

						// calculate the average of responsetime and uptime for 365 days range 
						averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data, '365d');

					}

					if (typeof found_7d === 'undefined' || found_7d < 0 ||
						typeof found_30d === 'undefined' || found_30d < 0 ||
						typeof found_91d === 'undefined' || found_91d < 0
					) {

						// prepare the rest of pings date range array 
						let data_7d_tmp = [];
						let data_30d_tmp = [];
						let data_91d_tmp = [];

						data.map(item => {

							if (moment(item.date).unix() >= moment().add(-91, 'days').unix()) {
								data_91d_tmp.push(item)
							}
							if (moment(item.date).unix() >= moment().add(-30, 'days').unix()) {
								data_30d_tmp.push(item)
							}
							if (moment(item.date).unix() >= moment().add(-7, 'days').unix()) {
								data_7d_tmp.push(item)
							}
						});
						if (data_7d_tmp.length > 0) {
							pings_7d.push({
								monitorId: monitorId,
								data: data_7d_tmp
							})
							// calculate the average of responsetime and uptime for 7 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_7d_tmp, '7d');
						}
						if (data_30d_tmp.length > 0) {
							pings_30d.push({
								monitorId: monitorId,
								data: data_30d_tmp
							})
							// calculate the average of responsetime and uptime for 30 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_30d_tmp, '30d');
						}
						if (data_91d_tmp.length > 0) {
							pings_91d.push({
								monitorId: monitorId,
								data: data_91d_tmp
							})
							// calculate the average of responsetime and uptime for 91 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_91d_tmp, '91d');
						}

					}

					this.setState({
						pings_365d,
						pings_7d,
						pings_30d,
						pings_91d,
						averagePingsDataPerMonitor
					})

					this.stop_monitorLoading(monitorId)



				})
				.catch(error => {
					this.stop_monitorLoading(monitorId)
				})



		})
	} */

	/**
	 * 
	 * @param {*} data 
	 */
	collectAllAffectedMonitors(data){
		
		const items = [];
		data.events.map( event => {
			event.monitors.map( monitor => {
				if(!items.includes(monitor.name)) 
					items.push(monitor.name)
			}) 
		})


		this.setState({
			affectedMonitors : items 
		})


	}

	getSelectedUrl() {

		return new Promise(async (resolve, reject) => {

			// Get the selected url 
			const url = window.location.href;
			const hostname = new URL(url).hostname;
			let statusPageType = this.state.statusPageType;

			// Get the native domain name from the url (Parse domain without subdomain) 
			var parsedURL = psl.parse(hostname);


			if (!hostname.match(this.state.regex_hostedDomain)) statusPageType = 'custom-domain';

			this.setState({
				statusPageType: statusPageType,
				statusPageDomain: hostname,
				statusPageNativeDomain: parsedURL.domain
			}, () => {
				resolve()
			})
		})
	}

	calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data, timeRange) {
		if (typeof data !== 'undefined') {

			let averageData = data.reduce((accumulator, currentValue) => {
				accumulator = {
					responsetime: (typeof accumulator !== 'undefined' && !isNaN(accumulator.responsetime))
						? parseFloat(accumulator.responsetime) + parseFloat(currentValue.responsetime)
						: parseFloat(currentValue.responsetime),
					uptime: accumulator.uptime + parseFloat(currentValue.uptime)
				}

				return accumulator;
			}, {
				responsetime: 0,
				uptime: 0
			});


			averageData = {
				responsetime: averageData.responsetime / data.length,
				uptime: averageData.uptime / data.length
			}

			let tmp = (typeof averagePingsDataPerMonitor !== 'undefined') ? averagePingsDataPerMonitor : {};

			if (typeof tmp[monitorId] === 'undefined') tmp[monitorId] = {};
			tmp[monitorId][timeRange] = averageData;





			return tmp;
		}
	}

	


    async componentDidMount(){
      

		this.getSelectedUrl()
		.then( async response => {


			this.getStatusPageInfo();
			 // Get the last 30 days incidents :  
			 this.getIncidentInfo();
		})
    }

	render(){

		let isResolved = false ;
		let resolvedPeriod = null ;
		const resolvedEvent = this.state.incidentData!==null ? this.state.incidentData.events.find(x => x.type.toLowerCase() === 'resolved') : null;
		const firstEvent = this.state.incidentData!==null ? this.state.incidentData.events[this.state.incidentData.events.length - 1] : null;
		if( typeof resolvedEvent!=='undefined' && resolvedEvent!==null) {
			isResolved = true ;
			resolvedPeriod = diffDates(moment(firstEvent.created_at).format() , moment(resolvedEvent.created_at).format())
			resolvedPeriod = durationToString(resolvedPeriod)
			 
		}


		let progressbar_value = 0;
		let progressbar_value_status = 'danger';
		
		if( typeof this.state.incidentData!=='undefined' && 
			this.state.incidentData!==null && 
			this.state.incidentData.is_scheduled === true ){ // Scheduled Maintenance 
			const time_left_to_ends = moment(this.state.incidentData.scheduled_until).diff(moment()).valueOf();
			const time_left_to_start = moment(this.state.incidentData.scheduled_for).diff(moment()).valueOf();

			if( moment(this.state.incidentData.scheduled_for) < moment() && moment() < moment(this.state.incidentData.scheduled_until)){
				const fulltime = moment(this.state.incidentData.scheduled_until).diff(moment(this.state.incidentData.scheduled_for)).valueOf();
				progressbar_value = (( fulltime - time_left_to_ends) / fulltime) * 100;
			} else if (moment() >= moment(this.state.incidentData.scheduled_until)){
				progressbar_value = 100;
			}
		}

		if(progressbar_value <= 30) progressbar_value_status = 'danger';
		else if(progressbar_value > 30 && progressbar_value <= 60 ) progressbar_value_status = 'warning';
		else if(progressbar_value > 60 && progressbar_value <= 80 ) progressbar_value_status = 'pre-success';
		else if(progressbar_value > 80  ) progressbar_value_status = 'success';

		return(
			<React.Fragment>
				<Favicon url={`https://s2.googleusercontent.com/s2/favicons?domain=https://${this.state.statusPageNativeDomain}`} />
				<Helmet>
					<title>{`${typeof this.state.statusPageName !== 'undefined' && this.state.statusPageName !== null ? this.state.statusPageName : 'Odown'} Status`}</title>
				</Helmet>
				<div className="global-header" style={{ backgroundColor: this.state.statusPageBackgroundColor }} >
					{this.state.notFound === false && this.state.isLoading_statusPageInfo === false &&
						<Navbar
							logo_url={this.state.statusPageLogoUrl}
							homepageLink={this.state.statusPageHomePageLink}
							name={this.state.statusPageName}
							backgroundColor={this.state.statusPageBackgroundColor}
							allowSubscriptions={this.state.allowSubscriptions}
							allowedChannelsSubs={this.state.allowedChannelsSubs} />
					}
					{
						this.state.notFound === false
						&& <div className="header-wrapper">
							<div className="header-content">
								<div className="container">
									<div className={`white-card status-wrapper ${
										this.state.isLoading_statusPageInfo 
											? '' 
											: (
												this.state.globalStatus === 'down' 
												? 'is-down' 
												: (
													this.state.globalStatus === 'partial' 
													? 'is-partial-down' 
													: 'is-up'
												)
											)}`}>
										{
											this.state.isLoading_statusPageInfo
												? <div className="loading-wrapper"><LoadingSpinner /></div>
												:
												<>
													<div className="status--icon_flag">
														<StatusIndicator 
															status={
																this.state.globalStatus === 'up'
																? 'success'
																: (
																	this.state.globalStatus === 'partial'
																	? 'warning'
																	: 'danger'
																)
															}
															title={``}
															size={`medium`} />
													</div>
													<div className="status--tx-content">
														<div className="status--title">Current status</div>
														<div className="status--global_state_vl" >
															{
																this.state.globalStatus === 'down'
																	? 'All services are down'
																	: (
																		this.state.globalStatus === 'partial'
																			? 'Some services are down'
																			: 'All services are online'
																	)

															}
														</div>
														<div className="status--current-time">
															{
																`As of ${moment().format('DD MMMM YYYY, hh:mm A')}`
															}
														</div>
													</div>
													<div className="incident-link" >
														<Link to={`/incidents`} ><IncidentIcon /> Incident History</Link></div>
												</>
										}
									</div>
								</div>
							</div>
							<div className="header-bottom-polygon" >
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"  >
								<polygon fill="white" points="0,100 100,0 100,100" ></polygon>
							</svg>	
							</div>		
						</div>
					}
				</div>
				<div className="body-wrapper">
					<div className="body-content incident-details">
						<div className="container">
							<div className="navbar-actions" >
								<div className="btn btn-link"><Link to={`/incidents`} ><BackIcon /> Back to incident history</Link></div> 
							</div>
							{
								this.state.isLoading 
								? <div className="loading-wrapper"><LoadingSpinner /></div>
								: (

									typeof this.state.incidentData === 'undefined' || this.state.incidentData === null
									? <div className="white-card">
										<span>Incident not found</span>	
									</div>
									: <div className="incidents-item white-card" >
									<div className="incident_item-wrapper">
										<div className="incident--name">{ this.state.incidentData.name }</div>
										{
											this.state.incidentData.is_scheduled === true && 
											<div className="incident-scheduled--period">{
												// Scheduled for Oct 15, 20:23 - 20:53 UTC

												//Scheduled for Oct 08, 16:40 - 08:48 UTC // year = current + month + day 
												//Scheduled for Oct 08, 16:40 - Dec 12, 08:48 UTC // year = current  
												//Scheduled for Oct 08, 2022 16:40 - Dec 12, 2022 08:48 UTC // year  + month
												moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment(this.state.incidentData.scheduled_until).utc(0).format('YYYY') &&
												moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment().utc(0).format('YYYY') && 
												moment(this.state.incidentData.scheduled_for).utc(0).format('M') === moment(this.state.incidentData.scheduled_until).utc(0).format('M') &&
												moment(this.state.incidentData.scheduled_for).utc(0).format('DD') === moment(this.state.incidentData.scheduled_until).utc(0).format('DD')  
												? `Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, HH:mm')} - ${moment(this.state.incidentData.scheduled_until).utc(0).format('HH:mm')} UTC` 
												: moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment(this.state.incidentData.scheduled_until).utc(0).format('YYYY') &&
													moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment().utc(0).format('YYYY')
													? `Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, HH:mm')} - ${moment(this.state.incidentData.scheduled_until).utc(0).format('MMM DD, HH:mm')} UTC` 
													: `Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, YYYY HH:mm')} UTC - ${moment(this.state.incidentData.scheduled_until).utc(0).format('MMM DD, YYYY HH:mm')} UTC`


												// moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment(this.state.incidentData.scheduled_until).utc(0).format('YYYY') &&
												// moment(this.state.incidentData.scheduled_for).utc(0).format('YYYY') === moment().utc(0).format('YYYY') // year = current year  	
												// ? `1Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, HH:mm')} - ${moment(this.state.incidentData.scheduled_until).utc(0).format('HH:mm')} UTC` 
												// : moment(this.state.incidentData.scheduled_for).utc(0).format('M') === moment(this.state.incidentData.scheduled_until).utc(0).format('M')  
												// 	? `2Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, YYYY HH:mm')} - ${moment(this.state.incidentData.scheduled_until).utc(0).format('HH:mm')} UTC` // Same year, same month 
												// 	: `3Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, YYYY HH:mm')} UTC - ${moment(this.state.incidentData.scheduled_until).utc(0).format('MMM DD, HH:mm')} UTC` // Same year 
												// : `4Scheduled for ${moment(this.state.incidentData.scheduled_for).utc(0).format('MMM DD, YYYY HH:mm')} UTC - ${moment(this.state.incidentData.scheduled_until).utc(0).format('MMM DD, YYYY HH:mm')} UTC` // Different years
											}</div>
										}
										{
											isResolved && resolvedPeriod!==null && resolvedPeriod!=='' && 
											<div className="incident-resolved_period">
												{
													`Resolved after about ${resolvedPeriod}`
												}
											</div>
										}
										{
											this.state.incidentData!==null && this.state.incidentData.is_scheduled === true && 
											<div className="act-inc_progressbar">
												<div className={`act-inc_pbar--content ${progressbar_value_status}`} style={{width : progressbar_value+'%'}}></div>
											</div>
										}
										<div className="incident--body">
											<div className="incident-events">
												<ul>
												{
													this.state.incidentData.events.map( (item , index) => {
														return (
															<li className="" key={index}>
																<div className="historyEvents--timeline">
																	<span className="timeline-icon"><i className="status-icon"></i></span>
																</div>
																<div className="historyEvents--item">
																	<div className={`event-type ${item.type.toLowerCase()}`}>{this.state.incidentData.is_scheduled === true ? getMaintenanceEvents(item.type) : getIncidentEvents(item.type)}</div>
																	<div className="event-msg_content">
																		<div className="msg" >{item.message}</div>
																		<div className="outline-info" >
																			<span className="outline_info-date" >{`Posted ${moment(item.created_at).fromNow()} . ${moment(item.created_at).format('MMM DD, hh:mm A')}`}</span>
																		</div>
																	</div>
																</div>
															</li>
														)
													})
												}
												</ul>
											</div>
											{
												this.state.affectedMonitors.length > 0 && 
												<div className="affected-services">
													<label>{
														this.state.incidentData.is_scheduled === true 
														? `This scheduled maintenance affects`
														: `Affected services`
													}</label>
													<ul>
														{
															this.state.affectedMonitors.map(( item , index) => {
																return (
																	<li  key={index} >{item}</li>
																)
															})
														}
													</ul>
												</div>
											}
										</div>
										
									</div>
								</div>
								)
							}
						</div>
					</div>
				</div>
				<Footer />	 
			</React.Fragment>
		)
	}
}